<template>
  <div class="body">
    <!-- 门诊缴费记录详情 -->
    <div class="pay-record">
      <div class="pay-record-item">
        <span class="pay-record-item-label">就诊人姓名：</span
        >{{ recordDetail.name }}
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">支付时间：</span
        >{{ recordDetail.tradeFinishedTime }}
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">支付金额：</span
        ><span style="color: #ee0a24"
          >￥{{ recordDetail.totalPayFee / 100 }}</span
        >
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">支付方式：</span
        >{{ recordDetail.tradeWayInfo }}
      </div>
      <div
        class="pay-record-item"
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      >
        <span class="pay-record-item-label">票据流水号：</span
        >{{ recordDetail.invoiceNo }}
      </div>
      <!-- style="white-space:nowrap;overflow:hidden;text-overflow: ellipsis;" -->
      <div class="pay-record-item">
        <span class="pay-record-item-label">接诊科室：</span
        ><span>{{ recordDetail.bizDeptName }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">接诊医生：</span
        ><span>{{ recordDetail.bizDoctorName }}</span>
      </div>
    </div>
    <div class="pay-record" v-for="(v, i) in recordDetail.details" :key="i">
      <div class="pay-record-item">
        <span class="pay-record-item-label">缴费项目：</span>
        <span class="pay-record-item-value"> {{ v.detailName }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">项目单位：</span
        ><span class="pay-record-item-value">{{ v.itemUnits }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">项目规格：</span
        ><span class="pay-record-item-value">{{ v.detailSpace }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">项目数量：</span
        ><span class="pay-record-item-value">{{ v.detailNum }}</span>
      </div>
      <div class="pay-record-item">
        <span class="pay-record-item-label">总价格：</span>
        <span style="color: #ee0a24">￥{{ v.detailSumFee / 100 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentRecordDetail",
  data() {
    return {
      recordDetail: {},
      payState: {
        0: "去支付",
        1: "已支付",
        2: "退款中",
        3: "已退款",
        4: "已关闭",
        5: "已取消",
      },
    };
  },
  methods: {
    getDataList() {
      this.recordDetail = JSON.parse(sessionStorage.getItem("recordDetail"));
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
}
.bar-code {
  height: 150px;
  font-size: 14px;
  overflow: hidden;
}
.bar-code canvas {
  width: 8rem;
  height: 100px;
}
.bar-code-box {
  height: 120px;
  line-height: 20px;
  margin: auto;
  margin-top: 20px;
}
.pay-record {
  width: 9.5rem;
  background-color: #ffff;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
  padding: 5px;
}
.pay-record-item {
  font-size: 0.4rem;
  line-height: 26px;
  text-align: left;
}
.pay-record-item-label {
  width: 2.5rem;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
  vertical-align: top;
}
.pay-record-item-value {
  width: 6.5rem;
  display: inline-block;

}
</style>
